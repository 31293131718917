<template>
  <div id="app">
    <header>
      <nav>
        <ul>
          <li><a href="#about">About</a></li>
          <li><a href="#products">Products</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>

    <main>
      <section id="hero">
        <h1>Problem Solver Software LLC</h1>
        <h2>Delivering Solutions from Portland, Oregon</h2>
        <a href="#products" class="cta-button">Explore Our Apps</a>
      </section>

      <section id="about">
        <h2>About Us</h2>
        <p>At Problem Solver Software, we specialize in creating innovative app store apps that solve real-world problems. Our passion is delivering software solutions that make life easier and more enjoyable.</p>
      </section>

      <section id="products">
        <h2>Our Products</h2>
        <div class="product">
          <h3>Recipe Radar</h3>
          <p>Your ultimate cooking companion. Never wonder what to cook again!</p>
          <a href="https://reciperadar.app" target="_blank" class="product-link">Learn More</a>
        </div>
        <!-- Add more products here as needed -->
      </section>

      <section id="contact">
        <h2>Get in Touch</h2>
        <p>Interested in our products or have a problem that needs solving? We'd love to hear from you!</p>
        <a href="mailto:problemsolversoftwarellc@gmail.com" class="contact-button">Contact Us</a>
      </section>
    </main>

    <footer>
      <p>&copy; {{ new Date().getFullYear() }} Problem Solver Software LLC. All rights reserved.</p>
      <p><small><a href="/recipe-radar/privacy" style="color: #666; text-decoration: underline; font-size: 0.8em;">Privacy Policy</a></small></p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  color: #333;
}

#app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

header {
  background-color: #f4f4f4;
  padding: 1rem 0;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

main {
  padding: 2rem 0;
}

section {
  margin-bottom: 3rem;
}

h1, h2, h3 {
  color: #2c3e50;
}

#hero {
  text-align: center;
  padding: 3rem 0;
}

#hero h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

#hero h2 {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 2rem;
}

.cta-button, .contact-button, .product-link {
  display: inline-block;
  background-color: #42b983;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover, .contact-button:hover, .product-link:hover {
  background-color: #3aa876;
}

.product {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

footer {
  text-align: center;
  padding: 1rem 0;
  background-color: #f4f4f4;
}
</style>